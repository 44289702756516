import React from 'react'
import { groupBy, get, map } from 'lodash'
import { ContentfulQuery } from '../../../components/ContentfulDataProvider'

const convertItem = ({
  fields: { parent, slug, menuTitle, title, noContent },
}) => ({
  label: menuTitle || title,
  slug,
  parent,
  ...(noContent || {
    href: `/${slug}`,
  }),
})

const buildTree = (list, localUrlPrefix = '') => {
  const converted = map(list, convertItem)
  const grouped = groupBy(
    converted,
    item => get(item, 'parent.fields.slug') || '',
  )
  return (grouped[localUrlPrefix] || []).map(item => ({
    ...item,
    subItems: grouped[item.slug],
  }))
}

const DPTopNavItems = ({ children, localUrlPrefix }) => (
  <ContentfulQuery
    query={{
      content_type: 'page',
      select:
        'fields.title,fields.slug,fields.menuTitle,fields.noContent,fields.parent',
      'fields.showInMenu': true,
      order: 'fields.order',
    }}
  >
    {items => children(buildTree(items, localUrlPrefix))}
  </ContentfulQuery>
)

export default DPTopNavItems
